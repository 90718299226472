import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import TopHero from "../components/tophero"
import mapImage from "../images/location-green.svg"
import phoneImage from "../images/phone-green.svg"
import faxImage from "../images/fax-icon.svg"
import mobileImage from "../images/mobile-icon.svg"
import emailImage from "../images/mail-green.svg"
import alertIcon from "../images/alert-icon.svg"
import greenArrow from "../images/arrow-green-left.svg"
import checkMark from "../images/check-mark.svg"
import infoIcon from "../images/info-green.svg"
import rightArrow from "../images/right-green-arrow.svg"
import RepeatingIcons from "../components/repeatingicons"
import CustomTeaser from "../components/customteaser"
import SEO from "../components/seo"
import YtViewCount from "../components/ytviewcount"
import YtDatePublished from "../components/ytdatepublished"
import LinkList from '../components/linkslist'
import Banner from '../components/banner'
import Accordion from "../components/accordion"
import Tabs from '../components/tabs'
import Divider from '../components/divider'
import Follow from '../components/followus'
import ContactWidget from '../components/contactwidget'
import $ from 'jquery'
import EventNewsTabs from '../components/eventnewstabs'

class PageTemplate extends Component {

  constructor() {
    var today = new Date();
    var month = (today.getMonth() + 1).toString();
    if (month.length < 2) {
      month = `0${month}`;
    }
    var day = today.getDate().toString();
    if (day.length < 2) {
      day = `0${day}`;
    }

    var date = today.getFullYear() +''+month+ ''+day;
    super();
    this.state = {
      currentDateTime: date,
    }
  }

  componentDidMount() {

    if($('#dars-widget-container').length > 0) {
      var $vnScript = $.noConflict();
        var data = {site_access_key: $vnScript('#dars-widget-container').attr("data-site-access-key"),site_url: window.location.hostname};
        var data_environment = $vnScript('#dars-widget-container').attr('data-environment');
        //Handle page mutation event listeners to observer the search widget appearing
        var observer = window.observer
        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || observer;
        var targetSubmitHandler = document.querySelector('#dars-widget-container');

        // create an observer instance
        var observerSubmitHandler = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                if(mutation.type = "childList" && mutation.addedNodes.length > 0) {
                    //Submit handler must be a mutation observer because the search form is loaded async
                    $vnScript("#edit-v4-submit").click(function(event) {
                        //Do the search
                        event.preventDefault();
                        var topics = $vnScript('#edit-v4-topics');

                        var service_areas = encodeURI($vnScript('#edit-v4-service-areas').val());
                        var site_access_key = $vnScript('#dars-widget-container').attr('data-site-access-key');
                        var data_environment = $vnScript('#dars-widget-container').attr('data-environment');
                        var stage = $vnScript('#v4-dars-widget').attr('data-stage');
                        var return_url = $vnScript('#v4-dars-widget').attr('data-return-url');

                        if(topics.is("select")) {
                            var topicsSelect = true;
                            var url = 'https://'+data_environment+'/searchresults?citycountyzip='+service_areas+'&keys=&topic='+encodeURI(topics.val().split(','))+'&programonly=true&nonationwide=true&accesskey='+site_access_key;
                        } else {
                            var topicsSelect = false;
                            var url = 'https://'+data_environment+'/searchresults?citycountyzip='+service_areas+'&keys='+encodeURI(topics.val())+'&topic=&programonly=true&nonationwide=true&accesskey='+site_access_key;
                        }
                        //location.href = url;
                        if(stage != 3) {
                            window.open(
                                url
                                //'_blank' //<- This is what makes it open in a new window.
                            );
                        } else {

                            var dataObj = {site_access_key: site_access_key, data_environment: data_environment, site_url: window.location.hostname, topics: encodeURI(topics.val()), service_areas: service_areas, topicsSelect: topicsSelect};
                            localStorage.setItem('dataurl', JSON.stringify(dataObj));
                            window.open(return_url);
                        }
                    });
                }
            });
        });

        // configuration of the observer:
        var config = { attributes: true, childList: true, characterData: true };

        // pass in the target node, as well as the observer options
        observerSubmitHandler.observe(targetSubmitHandler, config);

        //Do AJAX call to grab the widget from the API
        $vnScript.ajax({
            url: "https://"+data_environment+"/api/dars-widget",
            data: JSON.stringify(data),
            dataType: 'text',//'json',
            type: "POST",
            async: true,
            success: function (returnJson) {
                console.log('We did it!');
                console.log(JSON.parse(returnJson));

                var returnObj = JSON.parse(returnJson);
                $vnScript('#dars-widget-container').html(returnObj['template']);
            },
            error: function (xhr, status, error) {
                console.log(error);
            }
        });
    }
  }

  render() {
    const currentPage = this.props.data.nodeLandingPage
    var groupMenu = this.props.data.allMenuLinkContentMenuLinkContent
    const relatedEvents = this.props.data.allNodeEvent
    const relatedNews = this.props.data.allNodeNews
    const allAlerts = this.props.data.allNodeAlert
    const contactPage = this.props.data.allNodeContactPage
    const meta = currentPage.relationships.field_meta_data
    var moment = require('moment'); // require
    var filterEvents = relatedEvents.edges.filter(item => moment(item.node.field_start_date).format('YYYYMMDD') >= this.state.currentDateTime );
    filterEvents = filterEvents.slice(0,6);
    var overviewContent = '';
    var quickLinks = '';
    currentPage.relationships.field_landing_flexible && currentPage.relationships.field_landing_flexible.map((layout,key) => (
      layout.__typename === "paragraph__agency_overview" ? (
        overviewContent = layout.field_description
      ) : (
        layout.__typename === "paragraph__repeating_title_and_repeating_li" ? (
            layout.relationships.field_links_section && layout.relationships.field_links_section.map((section,skey) => (
              section.field_repeating_link && section.field_repeating_link.map((link,lkey) => (
                quickLinks = quickLinks + ', '+link.title
              ))
            ))

        ) : (
          null
        )
      )
    ))
    return (
        <Layout >
        <SEO
            title={currentPage.relationships.field_hero.field_title_html.value}
            overviewDesc={overviewContent}
            metaData={meta}
            quickLinks = {quickLinks}
            locationOrigin={this.props.location.origin + currentPage.path.alias}
            domen={this.props.location.origin}
        />

        <div className={"site-content node-landing_page node-"+currentPage.drupal_internal__nid}>
          {currentPage.relationships.field_hero ? (
            <TopHero landing={currentPage} menu={groupMenu} showNews={currentPage.field_show_news_in_menu} showEvents={currentPage.field_show_events_in_menu} showDocs={currentPage.field_show_forms_docs_in_menu} alerts={allAlerts} islanding="1" contactPage={contactPage} />
          ) : (
            null
          )}

          {currentPage.relationships.field_parent_agency !== null && currentPage.relationships.field_parent_agency.relationships.field_hero.field_title_html ? (
            <section className="breadcrumb">
              <div className="container">
                <ul className="uk-breadcrumb breadcrumb-list">
                  <li><Link to="/">Home</Link></li>
                  <li><Link to={currentPage.relationships.field_parent_agency.path.alias}>{currentPage.relationships.field_parent_agency.relationships.field_hero.field_title_html.value}</Link></li>
                  <li className="uk-disabled">{currentPage.relationships.field_hero.field_title_html.value}</li>
                </ul>
              </div>
            </section>
          ) : (
            null
          )}

          {currentPage.relationships.field_landing_flexible && currentPage.relationships.field_landing_flexible.map((layout,key) =>(
            layout.__typename === 'paragraph__agency_overview' ? (
              <section className="agency-overview" key="paragraph__agency_overview">
                <div className="container">
                  <ul data-uk-accordion="toggle: > div > .uk-accordion-title" className="uk-accordion">
                    <li className="uk-open">

                      <div className="accordion-title-wrp uk-flex">
                        <div className="headline-wrp">
                          <h2 className="h3">{layout.field_title}</h2>
                          {currentPage.field_link ? (
                            <a className="alert-link" href={currentPage.field_link.uri.replace("internal:","")}>
                              <img className="alert-icon" src={alertIcon} alt="alert" />
                              {currentPage.field_link.title}
                              <img className="arrow-icon" src={greenArrow} alt="alert-arrow" />
                            </a>
                          ) : (
                            null
                          )}
                        </div>
                        <a className="uk-accordion-title more-less" data-uk-toggle="target: .more-less" href="#" aria-expanded="true">Less</a>
                        <a className="uk-accordion-title more-less up" hidden data-uk-toggle="target: .more-less" href="#" aria-expanded="true">More</a>
                      </div>

                      <div className="uk-accordion-content">
                        <div className="uk-grid board-grid" data-uk-grid="">

                          <div className="uk-width-5-6@m overview uk-first-column">
                            <p>{layout.field_description}</p>
                            {layout.field_link ? (
                              <a className="more" href={layout.field_link.uri.replace("internal:","")}>
                                {layout.field_link.title ? (
                                  <span>
                                  {layout.field_link.title }
                                  </span>
                                ) : (
                                  <span>
                                    READ MORE
                                  </span>
                                )}

                              </a>
                            ) : (
                              null
                            )}

                          <div className="uk-grid uk-child-width-1-3@m" data-uk-grid>
                            {layout.relationships.field_contact.map((contact,index) =>(
                              contact.__typename === 'paragraph__contact_address' ? (
                                <div className="contact-info" key={index}>
                                  <img src={mapImage} alt="map"/>
                                  <div className="address">
                                    <div className="address-line-1"  dangerouslySetInnerHTML={{ __html: contact.field_address }} />
                                    <div className="address-line-2"  dangerouslySetInnerHTML={{ __html: contact.field_address_line_2 }} />
                                    {contact.field_address && contact.field_address_line_2 ? (
                                      <div>
                                        <a className="viewmap" href={"https://www.google.com/maps/search/" + encodeURIComponent(contact.field_address) + encodeURIComponent(contact.field_address_line_2)} target="_blank" rel="noreferrer">View Map</a>
                                        <a className="directions" href={"https://www.google.com/maps/dir/Current+Location/" + encodeURIComponent(contact.field_address) + encodeURIComponent(contact.field_address_line_2)} target="_blank" rel="noreferrer">Get Directions</a>
                                      </div>
                                    ) : (
                                      null
                                    )}
                                  </div>
                                </div>
                              ) : (
                                contact.__typename === 'paragraph__contact_phone' ? (
                                  <div className="contact-info" key={index}>
                                    <img src={phoneImage} alt="phone"/>
                                    <div className="contact-info-wrapp">
                                      <a href={"tel:" + contact.field_phone}>{contact.field_phone}</a>
                                      {contact.field_title ? (
                                        <div>{contact.field_title}</div>
                                      ) : (
                                        null
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  contact.__typename === 'paragraph__contact_email' ? (
                                    <div className="contact-info" key={index}>
                                      <img src={emailImage} alt="email" />
                                      <div className="contact-info-wrapp">
                                        <a href={"mailto:" + contact.field_email}>{contact.field_email}</a>
                                      </div>
                                    </div>
                                  ) : (
                                    contact.__typename === 'paragraph__contact_custom_text' ? (
                                      <div className="contact-info" key={index}>
                                        {contact.field_description_wysiwyg ? (
                                          <div dangerouslySetInnerHTML={{ __html: contact.field_description_wysiwyg.processed }} />
                                        ) : (
                                          null
                                        )}

                                      </div>
                                    ) : (
                                      contact.__typename === 'paragraph__contact_mobile_phone' ? (
                                        <div className="contact-info" key={index}>
                                          <img src={mobileImage} alt="mobile" />
                                          <div className="contact-info-wrapp">
                                            <a href={"tel:" + contact.field_phone}>{contact.field_phone}</a>

                                          </div>
                                        </div>
                                      ) : (
                                        contact.__typename === 'paragraph__contact_fax' ? (
                                          <div className="contact-info" key={index}>
                                            <img src={faxImage} alt="fax" />
                                            <div className="contact-info-wrapp">
                                              <a href={"tel:" + contact.field_phone}>{contact.field_phone}</a>
                                            </div>
                                          </div>
                                        ) : (
                                          null
                                        )
                                      )
                                    )
                                  )
                                )
                              )
                            ))}
                          </div> {/*5-6*/}
                        </div>

                          {layout.relationships.field_media_image ? (
                            <div className="uk-width-1-6@m img-box">
                              {layout.field_link_2 ? (
                                <Link to={layout.field_link_2.uri.replace("internal:","")}>
                                  <div className="agency-director-wrp">
                                    <img src={layout.relationships.field_media_image.relationships.field_media_image.uri.url} alt={layout.field_name} />
                                    <div className="name">{layout.field_name}</div>
                                    <div className="title">{layout.field_position}</div>
                                  </div>
                                </Link>
                              ) : (
                                <div className="agency-director-wrp">
                                  <img src={layout.relationships.field_media_image.relationships.field_media_image.uri.url} alt={layout.field_name} />
                                  <div className="name">{layout.field_name}</div>
                                  <div className="title">{layout.field_position}</div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="uk-width-1-6@m">
                              {layout.field_name ? (
                                <div className="name-title">
                                  <div className="name">{layout.field_name}</div>
                                  <div className="title">{layout.field_position}</div>
                                </div>
                              ) : (
                                null
                              )}
                            </div>
                          )}
                        </div> {/*uk-grid*/}
                      </div> {/*uk-accordion-content*/}
                    </li>
                  </ul>
                </div>
              </section>
            ) : (
              layout.__typename === 'paragraph__repeating_icon_description_link' ? (
                <section className="login-find-activity-park" key="paragraph__repeating_icon_description_link">
                  <RepeatingIcons content={layout} />
                </section>
              ) : (
                layout.__typename === "paragraph__tabs_custom_teasers" ? (
                  <section className="landing-tabs" key="paragraph__tabs_custom_teasers">
                    <div className="container">
                      <Tabs content={layout} />
                    </div>
                  </section>
                ) : (
                  layout.__typename === "paragraph__repeating_title_and_repeating_li" ? (
                    <section className="links-list" key={"paragraph__repeating_title_and_repeating_li" + key}>
                      <div className="container">
                        <LinkList content={layout} />
                      </div>
                    </section>
                  ) : (
                    layout.__typename === "paragraph__custom_teaser" ? (
                      <section className="teaser" key={"paragraph__custom_teaser" + key}>
                        <CustomTeaser content={layout} />
                      </section>
                    ) : (
                      layout.__typename === 'paragraph__news_events_social_placeholder' ? (
                        <section className="media-section" key={"paragraph__news_events_social_placeholder" + key}>
                          <EventNewsTabs relatedNews={relatedNews} filterEvents={filterEvents} currentPage={currentPage} />
                        </section>
                      ) : (
                        layout.__typename === 'paragraph__fitness_programs_repeating' ? (
                          <div className="sub-landing_page" key={key}>
                            <section className="fitness uk-padding-remove-top">
                              <div className="container">
                                <div className="fitness-centers-activity">
                                  <div className="title-wrp">
                                    <div className="uk-grid title-grid" data-uk-grid>

                                      <div className="uk-width-1-4@m uk-flex">
                                        <div className="fitness-center-activity">Activity</div>
                                      </div>

                                    </div>
                                  </div>
                                  <ul data-uk-accordion="toggle: > .uk-grid > .activity-content > div > .uk-accordion-title">
                                    {layout.relationships.field_fitness_programs_list.map((program, pIndex) => (
                                      <li key={pIndex}>
                                        <div className="uk-grid uk-flex uk-flex-middle">
                                          <div className="activity-wrp uk-width-1-4@m uk-flex uk-flex-middle">
                                            <div>
                                              <img className="activity-icon" alt={program.field_title + " icon" + pIndex} src={program.relationships.field_icon.uri.url}/>
                                              {program.field_title}
                                            </div>
                                          </div>

                                          <div className="activity-content uk-width-auto@m">
                                            <div>
                                              <a href="#" className="title-icon uk-accordion-title">
                                                <img className="info-icon" alt={program.field_title + " info icon" + pIndex} src={infoIcon}/>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="uk-accordion-content">
                                          <div className="acc-wrp">
                                            <div className="uk-grid" data-uk-grid>
                                              <div className="uk-width-1-4">
                                                <div>
                                                  <img alt={program.field_title + " " + pIndex} src={program.relationships.field_media_image.relationships.field_media_image.uri.url}/>
                                                </div>
                                              </div>
                                              <div className="uk-width-3-4">
                                                <div dangerouslySetInnerHTML={{ __html: program.field_description_wysiwyg.processed}} />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>

                                </div>

                              </div>
                            </section>
                          </div>
                        ) : (
                          layout.__typename === 'paragraph__wysiwyg' ? (
                            <section key={"paragraph__wysiwyg" + key}>
                              <div className="container">
                                <div dangerouslySetInnerHTML={{ __html: layout.field_description_wysiwyg.processed}} />
                              </div>
                            </section>
                          ) : (
                            layout.__typename === "paragraph__banner" ? (
                              <section className="banner" key={"paragraph__banner" + key}>
                                <div className="container">
                                  <Banner content={layout} />
                                </div>
                              </section>
                            ) : (
                              layout.__typename === "paragraph__youtube_videos" ? (
                                <section className="pwc-channel" key={"paragraph__youtube_videos" + key}>
                                  <div className="container">
                                    <h2>PWC Channel</h2>
                                    <div id="pwc-channel" className="uk-position-relative uk-visible-toggle pwc-channel-wrp" tabIndex="-1" data-uk-slider>
                                      <div className="uk-slider-container">
                                        <ul className="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-grid uk-grid-match">

                                          {layout.relationships.field_youtube_video.map((video, index) => (
                                            <li className="yt-modal-video" key={index}>
                                              <div className="uk-card uk-flex uk-flex-column" data-uk-lightbox>
                                                <a href={"https://www.youtube.com/watch?v=" + video.field_video_id + "?autoplay=1"} aria-label={`play video with `+ video.field_title +` name`}>
                                                  <div className="uk-card-media-top" style={{backgroundImage: `url('https://i1.ytimg.com/vi/` + video.field_video_id + `/mqdefault.jpg')`}}></div>
                                                </a>
                                                <div className="uk-card-body">
                                                  <div className="video-info">
                                                    <YtViewCount videoId={video.field_video_id} />&nbsp;&middot;&nbsp;<YtDatePublished videoId={video.field_video_id} />
                                                  </div>
                                                  <h3><a href={"https://www.youtube.com/watch?v=" + video.field_video_id + "?autoplay=1"} aria-label={`open card with` + video.field_title + ` title`}>{video.field_title}</a></h3>
                                                </div>
                                              </div>
                                            </li>
                                          ))}

                                        </ul>
                                      </div>
                                      {layout.relationships.field_links_buttons && layout.relationships.field_links_buttons[0].field_link !== null ? (
                                        layout.relationships.field_links_buttons.map((button, index) => (
                                          <div className="button-wrp" key={index}>
                                            <a className="uk-button green-bgr" href={button.field_link.uri} target="_blank" rel="noreferrer">{button.field_link.title}</a>
                                          </div>
                                        ))
                                      ) : (
                                        null
                                      )}
                                      <a className="uk-position-center-left-out uk-position-small" href="#" data-uk-slidenav-previous data-uk-slider-item="previous" aria-label="previous slide"></a>
                                      <a className="uk-position-center-right-out uk-position-small" href="#" data-uk-slidenav-next data-uk-slider-item="next" aria-label="next slide"></a>
                                    </div>
                                  </div>
                                </section>
                              ) : (
                                layout.__typename === 'paragraph__service_finder' ? (
                                  <section key={"paragraph__service_finder"+key}>
                                    <div id="dars-widget-container" data-site-access-key="a9642c89fa" data-environment="seniornavigator.org"></div>
                                  </section>
                                ) : (
                                  layout.__typename === "paragraph__horizontal_divider" ? (
                                    <section className="divider" key={"paragraph__horizontal_divider" + key}>
                                      <Divider />
                                    </section>
                                  ) : (
                                    layout.__typename === "paragraph__social_links" ? (
                                      <section className="follow-us" key={"paragraph__social_links" + key}>
                                        <div className="container">
                                          <Follow content={layout} />
                                        </div>
                                      </section>
                                    ) : (
                                      layout.__typename === "paragraph__contact_widget" ? (
                                        <section className="contact-widget" key={"paragraph__contact_widget" + key}>
                                          <ContactWidget content={layout} />
                                        </section>
                                      ) : (
                                        layout.__typename === 'paragraph__accordion' ? (
                                          <section className="accordion-section" key={"paragraph__accordion"+key}>
                                            <Accordion content={layout} />
                                          </section>
                                        ) : (
                                          null
                                        )
                                      )
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )

          ))}

        </div>
        </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!, $groupmenu: String, $groupid: Int, $date: Date) {
    allNodeEvent(filter: {field_start_date: {gt: $date},relationships: {group_content__departments_group_node_event: {elemMatch: {relationships: {gid: {drupal_internal__id: {eq: $groupid}}}}}}}, sort: {fields: field_start_date, order: ASC}) {
      edges {
        node {
          field_start_date
          field_end_date
          field_address_line_1
          field_address_line_2
          title
          path {
            alias
          }
          title
          relationships {
            group_content__departments_group_node_event {
              label
              relationships {
                gid {
                  label
                  drupal_internal__id
                }
              }
            }
          }
        }
      }
    }

    allNodeAlert(sort: {fields: field_weight, order: ASC}) {
      edges {
        ...node__alertEdgeFragment
      }
    }

    allNodeNews(sort: {fields: created, order: DESC}, limit: 9, filter: {relationships: {group_content__departments_group_node_news: {elemMatch: {relationships: {gid: {drupal_internal__id: {eq: $groupid}}}}}}}) {
      edges {
        node {
          path {
            alias
          }
          title
          relationships {
            field_media_image {
              relationships {
                field_media_image {
                  uri {
                    url
                  }
                }
              }
            }
            group_content__departments_group_node_news {
              label
              relationships {
                gid {
                  label
                  drupal_internal__id
                }
              }
            }
          }
        }
      }
    }

    allNodeContactPage(filter: {relationships: {group_content__group_content_type_4f1d2aba752bd: {elemMatch: {relationships: {gid: {drupal_internal__id: {eq: $groupid}}}}}}}) {
      edges {
        node {
          title
          path {
            alias
          }
        }
      }
    }

    allMenuLinkContentMenuLinkContent(filter: {menu_name: {eq: $groupmenu}, enabled: {eq: true}}, sort: {fields: weight, order: ASC}) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          title
          link {
            title
            uri
          }
        }
      }
    }
    nodeLandingPage(id: { eq: $id }) {
      title
      drupal_internal__nid
      field_show_forms_docs_in_menu
      field_show_news_in_menu
      field_show_events_in_menu
      field_link {
        title
        uri
      }
      body {
        value
      }
      path {
        alias
      }
      relationships {

        field_meta_data {
          ...paragraph__meta_dataFragment
        }

        field_parent_agency {
          path {
            alias
          }
          relationships {
            field_hero {
              field_title_html {
                value
              }
            }
          }
        }
        field_landing_flexible {
          ... on paragraph__banner {
            field_description
            field_title
            field_link {
              uri
              title
            }
            relationships {
              field_media_image {
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on paragraph__contact_widget {
            field_title
            relationships {
              field_contact_widget {
                ... on paragraph__contact_address {
                  field_address
                  field_address_line_2
                }
                ... on paragraph__contact_email {
                  field_email
                }
                ... on paragraph__contact_mobile_phone {
                  field_phone
                }
                ... on paragraph__contact_phone {
                  field_title
                  field_phone
                }
                ... on paragraph__contact_link {
                  field_contact_link {
                    title
                    uri
                  }
                }
              }
            }
          }

          ... on paragraph__accordion {
            field_half_width
            field_title
            field_animate_items
            relationships {
              field_accordion_item {
                field_title
                field_description_wysiwyg {
                  processed
                }
              }
            }

          }

          ... on paragraph__horizontal_divider {
              id
            }

          ... on paragraph__service_finder {
            field_placeholder_info
          }

          ... on paragraph__agency_overview {
            field_title
            field_description
            field_name
            field_position
            field_link {
              title
              uri
            }
            field_link_2 {
              uri
            }
            relationships {
              field_contact {
                ... on paragraph__contact_address {
                  field_address
                  field_address_line_2
                }
                ... on paragraph__contact_email {
                  field_email
                }
                ... on paragraph__contact_phone {
                  field_phone
                  field_title
                }
                ... on paragraph__contact_custom_text {
                  field_description_wysiwyg {
                    processed
                  }
                }
                ... on paragraph__contact_fax {
                  field_phone
                }
                ... on paragraph__contact_mobile_phone {
                  field_phone
                }

              }
              field_media_image {
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on paragraph__news_events_social_placeholder {
            field_placeholder_info
          }
          ... on paragraph__custom_teaser {
            field_title
            field_description
            field_link {
              title
              uri
            }
            relationships {
              field_media_image {
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on paragraph__repeating_title_and_repeating_li {
            relationships {
              field_links_section {
                field_title
                field_repeating_link {
                  title
                  uri
                }
              }
            }
          }
          ... on paragraph__repeating_icon_description_link {
            id
            relationships {
              field_item {
                field_link {
                  title
                  uri
                }
                field_title
                field_description
                relationships {
                  field_icon {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on paragraph__tabs_custom_teasers {
            id
            field_rotation_interval
            relationships {
              field_tab_teaser {
                field_title
                field_tab_title
                field_link {
                  title
                  uri
                }
                field_link_2 {
                  title
                  uri
                }
                field_video_link {
                  title
                  uri
                }
                field_description
                relationships {
                  field_media_image {
                    relationships {
                      field_media_image {
                        uri {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on paragraph__wysiwyg {
            field_description_wysiwyg {
              value
              processed
            }
          }
          ... on paragraph__youtube_videos {
            relationships {
              field_links_buttons {
                field_link {
                  uri
                  title
                }
              }
              field_youtube_video {
                field_title
                field_video_id
              }
            }
          }
          ... on paragraph__social_links {
            field_title
            relationships {
              field_social_link {
                field_social_network
                field_link {
                  title
                  uri
                }
              }
            }
          }
        }
        field_hero {
          field_image_alignment
          field_title_html {
            value
          }
          relationships {
            field_media_image {
              relationships {
                field_media_image {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
        group_content__group_content_type_20b7e70e3cc2b {
          relationships {
            gid {
              label
              drupal_internal__id
            }
          }
        }
      }
    }
  }

  fragment node__landing_pageEdgeFragment on node__landing_pageEdge {
    node {
      title
      field_show_forms_docs_in_menu
      field_show_news_in_menu
      field_show_events_in_menu
      path {
        alias
      }
      body {
        value
      }
      relationships {
        field_parent_agency {
          path {
            alias
          }
          field_include_in_list
          relationships {
            field_hero {
              field_title_html {
                value
              }
            }
          }
        }
        group_content__group_content_type_20b7e70e3cc2b {
          relationships {
            gid {
              drupal_internal__id
            }
          }
        }
        field_hero {
          field_image_alignment
          field_title_html {
            value
          }
          relationships {
            field_media_image {
              relationships {
                field_media_image {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
