import React, { Component } from "react"

class Divider extends Component {

	render() {
		return (
		<div className="container">
			<hr/>
		</div>
		)
	}
}

export default Divider