import React, { Component } from "react"

class Follow extends Component {

	render() {
		const layout = this.props.content
		return (
			layout.relationships !== null && layout.relationships.field_social_link !== null ? (
				<div className="uk-grid follow-us-networks uk-flex" data-uk-grid>
						{layout.field_title !== null ? (
						<div className="uk-width-auto@m uk-flex uk-flex-top">
							<h2 className="h3">{layout.field_title}</h2>
						</div>
						) : (
							null
						)}
						<div className="uk-width-expand@m uk-flex">
						{layout.relationships.field_social_link.map((social, index) => (
							social.field_link !== null ? (
							<div className="uk-flex" key={index}>
								<a className={social.field_social_network} target="_blank" rel="noreferrer" href={social.field_link.uri} key={index}>{social.field_link.title}</a>
							</div>
							) : (
								null
							)
						))}
						</div>
				</div>
			) : (
				null
			)
		)
	}
}

export default Follow
