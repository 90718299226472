import React, { Component } from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import $ from "jquery"
import moment from 'moment'


class YtDatePublished extends Component {
  constructor() {
    super();
    this.state = {
      datePublished : '',
      response: []
    }
  }
  componentDidMount() {
    var dataUrl = "https://www.googleapis.com/youtube/v3/videos?part=snippet&id=" + this.props.videoId + "&key=AIzaSyCdLnEFswjzgLyj1kTsrfm2oZqCqtdS_jY";
    fetch( dataUrl )
    .then(res => res.json())
    .then(res => {
      this.setState({
        datePublished: res.items[ 0 ].snippet.publishedAt
      });
    })
  }
  render(props) {
    let pubDate = this.state.datePublished
    let datePrint = moment(pubDate).format("MMM DD, YYYY")

    return (
        <span>{datePrint}</span>
      )
  }

}

export default YtDatePublished
