import React, { Component } from "react"
import mapImage from "../images/location-green.svg"
import phoneImage from "../images/phone-green.svg"
import emailImage from "../images/mail-green.svg"
import greenArrow from "../images/right-green-arrow.svg"
import mobilePhone from "../images/mobile-icon.svg"


class ContactWidget extends Component {

	render() {
		const layout = this.props.content
		return (

			<div className="container">
				<h2 className="h3">{layout.field_title}</h2>
				<div className={layout.relationships.field_contact_widget.length < 4 ? ( ' uk-grid uk-child-width-auto@m uk-flex uk-flex-between contact-widget-wrp contact-widget-wrp-center' ) : ( 'uk-grid uk-child-width-auto@m uk-flex uk-flex-between contact-widget-wrp' )} data-uk-grid>
					{layout.relationships.field_contact_widget.map((widget, index) =>
						widget.__typename === 'paragraph__contact_address' ? (
							<div key={index}>
								<div className="address-box uk-flex">
									<img src={mapImage} alt="map"/>
									{widget.field_address && widget.field_address !== null ? (
										<div className="address-line-1">{widget.field_address}</div>
									) : (
										null
									)}
									{widget.field_address_line_2 && widget.field_address_line_2 !== null ? (
										<div className="address-line-2">{widget.field_address_line_2}</div>
									) : (
										null
									)}
								</div>
							</div>
						) : (
							widget.__typename === 'paragraph__contact_email' ? (
								<div key={index}>
									<div className="contact-info uk-flex">
										<img src={emailImage} alt="email" />
										<div className="contact-info-wrapp">
											<a href={'mailto:' + widget.field_email}>{widget.field_email}</a>
										</div>
									</div>
								</div>
							) : (
								widget.__typename === 'paragraph__contact_phone' ? (
									<div key={index}>
										<div className="contact-info uk-flex">
											<img src={phoneImage} alt="phone"/>
											<div className="contact-info-wrapp">
												<a href={'tel:' + widget.field_phone}>{widget.field_phone}</a>
												<div className="contact-info-hours">{widget.field_title}</div>
											</div>
										</div>
									</div>
								) : (
								widget.__typename === 'paragraph__contact_mobile_phone' ? (
									<div key={index}>
										<div className="contact-info uk-flex">
											<img src={mobilePhone} alt="phone"/>
											<div className="contact-info-wrapp">
												<a href={'tel:' + widget.field_phone}>{widget.field_phone}</a>
												<div className="contact-info-hours"></div>
											</div>
										</div>
									</div>
								) : (
									widget.__typename === 'paragraph__contact_link' ? (
										<div key={index}>
											<div className="contact-info-link uk-flex">
												<div className="link-to-contact-page">
													<a href={widget.field_contact_link.uri}>{widget.field_contact_link.title}</a>
												</div>
												<img src={greenArrow} alt="link" />
											</div>
										</div>
									) : (
										null
										)
									)
								)
							)
						)
					)}
				</div>
			</div>

		)
	}
}

export default ContactWidget
