import React, { Component } from "react"
import { Link } from "gatsby"

class EventNewsTabs extends Component {

    render() {
        const relatedNews = this.props.relatedNews
        const filterEvents = this.props.filterEvents
        const currentPage = this.props.currentPage
        var moment = require('moment');
        return (
           relatedNews && filterEvents && currentPage ? (
               <div className="container">
                 <ul className="uk-grid uk-child-width-1-3 uk-child-width-1-4@m uk-subnav uk-subnav-pill uk-flex tab-media-list" data-uk-grid data-uk-switcher="animation: uk-animation-fade">
                   {relatedNews.edges && relatedNews.edges.length > 0 ? (
                     <li><a className="news" href="#" aria-label="news" title="news">News</a></li>
                   ) : (
                     null
                   )}
                   {filterEvents.length > 0 ? (
                     <li><a className="events" href="#" aria-label="events" title="events">Events</a></li>
                   ) : (
                     null
                   )}
                 </ul>

                 <div className="uk-switcher tab-content">
                   {relatedNews.edges && relatedNews.edges.length > 0 ? (
                     <div id="home-news-tab-content" className="tab-slider-wrp uk-position-relative uk-visible-toggle uk-slider" tabIndex="-1" data-uk-slider>
                       <div className="uk-slider-container">
                         <ul className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-small uk-grid-match" data-uk-grid>
                         {relatedNews.edges.map((newsItem, nkey) => (
                           <li key={"news-item"+nkey}>
                             <div className="uk-card uk-flex uk-flex-column news-home-card">
                               <div className="inner-card">
                                 {}
                                 <a href="#" aria-label={newsItem.node.title + "open card"}>
                                   <div className="uk-card-media-top" style={newsItem.node.relationships.field_media_image && newsItem.node.relationships.field_media_image.relationships.field_media_image ?
  {backgroundImage: `url(${newsItem.node.relationships.field_media_image.relationships.field_media_image.uri.url})`} : null}></div>
                                 </a>
                                 <div className="uk-card-body">
                                   <Link to={newsItem.node.path.alias} aria-label={newsItem.node.title + "  open"}><h3>{newsItem.node.title}</h3></Link>
                                 </div>
                               </div>
                             </div>
                           </li>
                         ))}
                         </ul>
                       </div>
                         <a className="uk-position-center-left-out uk-position-small" href="#" data-uk-slidenav-previous data-uk-slider-item="previous" aria-label="previous">&nbsp;</a>
                         <a className="uk-position-center-right-out uk-position-small" href="#" data-uk-slidenav-next data-uk-slider-item="next" aria-label="next">&nbsp;</a>
                       <div className="button-wrp">
                         <Link className="uk-button green-bgr" to={currentPage.path.alias + "/news"}>All News</Link>
                       </div>
                     </div>
                   ) : (
                     null
                   )}

                   {/*events*/}
                  {filterEvents.length > 0 ? (
                    <div id="home-events-tab-content" className="tab-slider-wrp uk-position-relative uk-visible-toggle uk-slider" tabIndex="-1" data-uk-slider>
                      <div className="uk-slider-container">
                        <ul className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-small uk-grid-match">
                        {filterEvents.map((eventItem, ekey) => (
                          <li key={"event-item"+ekey}>
                            <div className="uk-card uk-flex uk-flex-column events-home-card">
                             <div className="inner-card uk-flex uk-flex-column">
                               <div className="uk-card-header">
                                 {moment(eventItem.node.field_start_date).format("dddd, MMMM D")}
                               </div>
                               <div className="uk-card-body">
                                 {eventItem.node.path ? (
                                   <Link to={eventItem.node.path.alias}><h3>{eventItem.node.title}</h3></Link>
                                 ) : (
                                   <h3>{eventItem.node.title}</h3>
                                 )}


                               </div>
                               <div className="uk-card-footer">
                                 {eventItem.node.field_start_date && eventItem.node.field_start_date ? (
                                   <div className="time">{moment(eventItem.node.field_start_date).format("hh:mm A") + " - "+ moment(eventItem.node.field_end_date).format("hh:mm A")}</div>
                                 ) : (
                                   null
                                 )}
                                 {eventItem.node.field_address_line_2 != null && eventItem.node.field_address_line_1 != null ? (
                                   <div className="location">{eventItem.node.field_address_line_1 + ", " + eventItem.node.field_address_line_2}</div>
                                 ) : (
                                   eventItem.node.field_address_line_1 != null ? (
                                     <div className="location">{eventItem.node.field_address_line_1}</div>
                                   ) : (
                                     null
                                   )
                                 )}
                               </div>
                             </div>
                            </div>
                          </li>
                        ))}
                        </ul>
                      </div>
                        <a className="uk-position-center-left-out uk-position-small" href="#" data-uk-slidenav-previous data-uk-slider-item="previous" aria-label="previous event slide">&nbsp;</a>
                        <a className="uk-position-center-right-out uk-position-small" href="#" data-uk-slidenav-next data-uk-slider-item="next" aria-label="next event slide">&nbsp;</a>
                      <div className="button-wrp">
                        <Link className="uk-button green-bgr" to={currentPage.path.alias + "/events"}>All Events</Link>
                      </div>
                    </div>
                  ) : (
                    null
                  )}


                 </div> {/*uk-switcher*/}

               </div>

            ) : (
                null
            )
        )
    }
}

export default EventNewsTabs
